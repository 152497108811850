import { Message, MessageBox, Loading } from "element-ui";
import { keepDecimal } from "./calc";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

export { moment };

// 显示loading
export const showLoading = () => {
  const loading = Loading.service({
    lock: true,
    background: "rgba(0, 0, 0, 0.7)"
  });
  window.fmLoadingList = window.fmLoadingList || [];
  window.fmLoadingList.push(loading);
};

// 隐藏loading
export const hideLoading = () => {
  window.fmLoadingList?.forEach(loading => {
    loading?.close?.();
  });
  window.fmLoadingList = [];
};

// 是否为'空'值
export const isEmpty = target =>
  !(target || target === false || target === 0) ||
  target === "undefined" ||
  target === "null";

// 获取路由地址 第一个路径名字
export const getRouteRootPathName = path => {
  return (path || window.location.hash.slice(1)).split("/").filter(i => i)[0];
};

export const getRawType = val => {
  /**
   * 获取对象数据类型
   * @param {any} val
   * @return {String} array string ...
   * */
  let toString = Object.prototype.toString;
  return toString
    .call(val)
    .slice(8, -1)
    .toLowerCase();
};

export function debounce(fn, delay = 500) {
  /**
   * @函数防抖
   * @param {Function} fn 需要防抖的函数
   * @param {Number} delay 延时
   * **/
  let timeoutId;
  return function(...args) {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export const downLoadBlob = (blobData, fileName) => {
  /**
   *下载二进制流文件
   *@param {blobData} blob 二进制图片
   **/
  // type 为需要导出的文件类型，此处为xls表格类型
  const blob = new Blob([blobData], { type: `application/x-xls` });
  // 兼容不同浏览器的URL对象
  const url = window.URL || window.webkitURL || window.moxURL;
  // 创建下载链接
  const downloadHref = url.createObjectURL(blob);
  // 创建a标签并为其添加属性
  let downloadLink = document.createElement(`a`);
  downloadLink.href = downloadHref;
  downloadLink.download = `${fileName}.xls`;
  // 触发点击事件执行下载
  downloadLink.click();
};

export const compressImg = (file, isCompressSize = true) => {
  /**
   *压缩图片文件
   *@param {File} file File文件
   **/
  function convertBase64UrlToBlob(urlData) {
    const bytes = window.atob(urlData.split(",")[1]); // 去掉url的头，并转换为byte
    // 处理异常,将ascii码小于0的转换为大于0
    const ab = new ArrayBuffer(bytes.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < bytes.length; i++) {
      ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/png" });
  }
  return new Promise(resolve => {
    try {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = function() {
        const _this = this; // 默认按比例压缩
        let w = _this.width;
        let h = _this.height;
        let max = 1920; //最大尺寸，超过就压缩到1920
        let width = w; //计算后的允许的最大宽高
        let height = h;
        let defaultSize = 300 * 1024; //图片默认大小
        let quality = 0.5; // 图片质量
        if (isCompressSize) {
          //是否压缩尺寸
          if (w > h && w / h < 5) {
            //如果宽高比例不超过5的长图（长图压缩至1920可能会糊）
            if (w > max) {
              width = max;
              height = Math.floor((max / w) * h);
            }
          } else if (h > w && h / w < 5) {
            if (h > max) {
              height = max;
              width = Math.floor((max / h) * w);
            }
          }
        }
        if (file.size < defaultSize) {
          quality = 0.75;
        }
        // 生成canvas
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d"); // 创建属性节点
        canvas.width = width;
        canvas.height = height;
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(_this, 0, 0, width, height); //绘制图片
        const data = canvas.toDataURL("image/jpeg", quality);
        const blob = convertBase64UrlToBlob(data);
        let newFile = new File([blob], file.name, { type: file.type });
        resolve(newFile);
      };
    } catch (e) {
      resolve(file);
    }
  });
};

export const showSuccess = (message, callback) => {
  /**
   *提示成功信息
   *@String message 提示文字
   *@Function callback 提示框关闭后的回调
   **/
  Message({
    type: "success",
    message,
    showClose: true,
    duration: 1000,
    onClose() {
      callback && callback();
    }
  });
};

export const showError = (message, callback) => {
  /**
   *提示错误信息
   *@String message 提示文字
   *@Function callback 提示框关闭后的回调
   **/
  Message({
    type: "warning",
    message,
    showClose: true,
    duration: 3000,
    onClose() {
      callback && callback();
    }
  });
};

export const showConfirm = (
  resolve,
  message = "此操作将永久删除该项，是否继续？",
  title = "提示",
  reject
) => {
  /**
   *公用确认框
   *@String message: 提示信息
   *@String title: 提示title
   *@Function resolve: 确认回调
   *@Function reject: 取消回调
   **/
  MessageBox.confirm(message, title, { type: "warning" })
    .then(() => {
      resolve && resolve();
    })
    .catch(action => {
      reject && reject(action);
    });
};

export const deepClone = obj => {
  /**
   *克隆对象
   *@Object | Array obj 对象
   **/
  let type = Object.prototype.toString.call(obj).toLowerCase();
  if (type === "[object object]" || type === "[object array]") {
    // 如果是json 或 array
    return JSON.parse(JSON.stringify(obj));
  }
  return obj;
};

export const flatJsonTree = (tree, children = "children") => {
  /**
   * 扁平化json树
   * @param {Object} tree json树
   * @param {String} children 子级字段名
   **/
  let res = deepClone(tree);
  return res
    .reduce((arr, cur) => {
      return arr.concat([cur], flatJsonTree(cur[children] || []));
    }, [])
    .map(item => {
      delete item[children];
      return item;
    });
};

export const dataURLtoBlob = dataURL => {
  /**
   * base64转blob
   * @param {String} dataURL base64url
   **/
  let arr = dataURL.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const imageUrlToBase64 = (url, showErrorMessage = true) => {
  /**
   * 网络图片转base64
   * @param {String} url 图片网络路径
   * @param {Boolean} showErrorMessage 是否显示图片失败提示
   **/
  let image = new Image();

  //解决跨域问题
  image.setAttribute("crossOrigin", "anonymous");
  image.src = url;
  return new Promise(resolve => {
    image.onload = () => {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      let quality = 0.8;
      let dataURL = canvas.toDataURL("image/jpeg", quality); //使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
      resolve(dataURL);
    };
    image.onerror = () => {
      if (showErrorMessage) {
        showError("图片下载失败");
      }
      resolve(false);
    };
  });
};

export const generateRandomNum = (len = 6) => {
  /**
   * 生成指定长度的随机数
   * @param {Number} len 长度
   **/
  const generator = () => Math.floor(Math.random() * `1${"0".repeat(len)}`);
  let num = generator();
  while (String(num).length !== len) {
    num = generator();
  }
  return num;
};

export const getByteLen = str => {
  /**
   * 设置缓存
   * @param {String} str
   */
  let len = 0;
  let re = /[^\x00-\xff]/gi;
  for (let item of str) {
    if (item.match(re) !== null) {
      len += 2;
    } else {
      len += 1; // 半角占用一个字节
    }
  }
  return len;
};

export const copyText = (text, success = true) => {
  /**
   * 复制文本
   * @param {String} text 需要复制的文本内容
   * @param {Boolean|Function} success 是否显示成功提示 或者复制成功后的回调
   **/
  // 数字没有 .length 不能执行selectText 需要转化成字符串
  let result = false;
  const textString = text.toString();
  let input = document.querySelector("#copy-input");
  if (!input) {
    input = document.createElement("textarea");
    input.id = "copy-input";
    input.readOnly = "readOnly"; // 防止ios聚焦触发键盘事件
    input.style.position = "fixed";
    input.style.left = "-1000px";
    input.style.zIndex = "-1000";
    document.body.appendChild(input);
  }

  input.value = textString;
  // ios必须先选中文字且不支持 input.select();
  selectText(input, 0, textString.length);
  if (document.execCommand("copy")) {
    document.execCommand("copy");
    result = true;
    if (success === true) {
      showSuccess("复制成功");
    } else if (typeof success === "function") {
      success();
    }
  }
  input.blur();
  // input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
  // 选择文本。createTextRange(setSelectionRange)是input方法
  function selectText(textbox, startIndex, stopIndex) {
    if (textbox.createTextRange) {
      //ie
      const range = textbox.createTextRange();
      range.collapse(true);
      range.moveStart("character", startIndex); //起始光标
      range.moveEnd("character", stopIndex - startIndex); //结束光标
      range.select(); //不兼容苹果
    } else {
      //firefox/chrome
      textbox.setSelectionRange(startIndex, stopIndex);
      textbox.focus();
    }
  }
  return result;
};

export const filterListTOName = (list, { name, id }) => {
  /**
   * 把数组字段改成id,name
   * @param {list} Array
   * @param {name} String
   * @param {id} String
   */
  let arr = [];
  list.forEach(e => {
    arr.push({
      name: e[name],
      id: e[id]
    });
  });
  return arr;
};

export const getTreeNameById = (tree, id, name, getNames = false) => {
  /**
   * 通关id找出树的name字段
   * @param {tree} Array
   * @param {name} String
   * @param {id} String
   * @param {getNames} Boolean
   */
  let nameList = [];
  let page = 0;
  let find = false;
  let func = treeList => {
    treeList.forEach(el => {
      if (find) {
        return;
      }
      nameList[page] = el.name;
      if (el.id == id) {
        find = true;
      } else {
        if (el.children && el.children.length) {
          page++;
          func(el.children);
        }
      }
    });
    if (!find) {
      nameList[page] = "";
      page--;
    }
  };
  func(tree);
  let result = "";
  if (getNames) {
    result = nameList;
  } else {
    if (nameList.length) {
      result = nameList[nameList.length - 1];
    } else {
      result = "";
    }
  }
  return result;
};

export const link = (arr, separator = "-") => {
  /**
   * 短线关联存在数据，例如：
   * 店组： 店 ; 店-组
   * @Array 需要处理的数据的数组集合
   **/
  if (!arr) return "";
  let str = "";
  arr = arr.filter(e => e);
  str = arr.join(separator);
  return str;
};

export const toThousands = num => {
  /**
   * 格式化数字等，以千分号隔开
   * @String | Number : num
   **/
  if (typeof num === "object") {
    if (!num) {
      num = "";
    }
  }
  if (typeof num === "undefined") {
    return;
  }
  num = String(num).split(".");
  let arr = num[0].split("").reverse();
  let res = [];
  for (var i = 0; i < arr.length; i++) {
    if (i % 3 == 0 && i != 0) {
      res.push(",");
    }
    res.push(arr[i]);
  }
  res.reverse();
  if (num[1]) {
    res = res.join("").concat("." + num[1]);
  } else {
    res = res.join("");
  }
  res = res.replace(/^-,/, "-"); // 去掉负号后面的逗号
  return res;
};

export const openWindow = path => {
  window.open(`${location.protocol}//${location.host}/#${path}`);
};

export const format = {
  comma: toThousands,
  link,
  phone(str, type = 1) {
    /**
     *格式化手机号格式 13028888888 -> 1 130 2888 8888 || 3 130****8888 || 2 130 **** 8888
     *支持字符串检索与多项匹配
     *@param {String} str: 手机号码字符串
     *@param {Number} type: 格式化类型
     **/
    if (typeof str != "string") return str;
    let reg = /(1\d{10}|\*{7}\d{4}|1\d{2}\*{4}\d{4})/g;
    if (!reg.test(str)) return str;
    let matched = str.match(reg);
    for (let i = 0; i < matched.length; i++) {
      // 遍历并替换
      let rep = matched[i].split("");
      if (type == 3) {
        rep.splice(3, 4, "*", "*", "*", "*");
      } else {
        rep.splice(3, 0, " ");
        if (type == 2) {
          rep.splice(4, 4, "*", "*", "*", "*");
        }
        rep.splice(8, 0, " ");
      }
      rep = rep.join("");
      str = str.replace(matched[i], rep);
    }
    return str;
  },
  // 格式化字节 100b 100kb 100M
  byte(num) {
    if (num > 1000 && num < 1000 * 1000) {
      return keepDecimal(num / 1000) + "kb";
    } else if (num > 1000 * 1000) {
      return keepDecimal(num / 1000 / 1000) + "M";
    }
    return num + "b";
  },
  date(target, type = 0) {
    /**
     * 格式化时间
     * @param {String|Number} target: 时间字符串或者时间戳
     * @param {String|Number} type 格式字符串(如：YYYY-MM-DD HH:mm:ss) 或者 格式类型0 1 2 3...
     * **/
    if (target === null || typeof target === "undefined") return "";
    if (typeof target !== "string" && typeof target !== "number") return target;
    const d = moment(target);
    // 处理类型6
    const handle6 = d => {
      // 本年
      if (d.year() === moment().year()) {
        return d.format("MM月DD日");
      }
      return d.format("YYYY年MM月DD日");
    };
    // 处理类型7
    const handle7 = d => {
      // 本年
      if (d.year() === moment().year()) {
        return d.format("MM-DD HH:mm");
      }
      return d.format("YYYY-MM-DD HH:mm");
    };
    // 处理类型10
    const handle10 = d => {
      const oneMinute = 1000 * 60;
      const oneHour = oneMinute * 60;
      const oneDay = oneHour * 24;
      const nowValue = Date.now();
      const targetValue = d.valueOf();
      // 1分钟内
      if ((nowValue - targetValue) / oneMinute < 1) {
        return "刚刚";
      }
      // 1小时内
      else if ((nowValue - targetValue) / oneMinute < 60) {
        return Math.floor((nowValue - targetValue) / oneMinute) + "分钟前";
      }
      // 1天以内
      else if ((nowValue - targetValue) / oneDay < 1) {
        return Math.floor((nowValue - targetValue) / oneHour) + "小时前";
      }
      // 10天内
      else if ((nowValue - targetValue) / oneDay < 10) {
        return Math.floor((nowValue - targetValue) / oneDay) + "天前";
      }
      return handle7(d);
    };
    // 处理类型13
    const handle13 = d => {
      // 今天
      if (d.isSame(moment(), "day")) {
        return d.format("HH:mm");
      }
      // 昨天
      if (d.isSame(moment().subtract(1, "day"), "day")) {
        return d.format("昨天 HH:mm");
      }
      // 本周
      if (d.isSame(moment(), "week")) {
        let week = d.day();
        switch (week) {
          case 1:
            return "星期一";
          case 2:
            return "星期二";
          case 3:
            return "星期三";
          case 4:
            return "星期四";
          case 5:
            return "星期五";
          case 6:
            return "星期六";
          case 0:
            return "星期日";
        }
      }
      // 本月
      if (d.isSame(moment(), "month")) {
        return d.format("MM月DD日 HH:mm");
      }
      return handle6(d);
    };
    // 处理类型14(type:1为今天不显示日期，2为今天显示日期)
    const handle14 = (d, type) => {
      // 今天
      if (d.isSame(moment(), "day")) {
        return d.format(type == 1 ? "HH:mm 今天" : "HH:mm  MM月DD日  今天");
      }
      // 昨天
      if (d.isSame(moment().subtract(1, "day"), "day")) {
        return d.format("HH:mm  MM月DD日  昨天");
      }
      // 明天
      if (d.isSame(moment().add(1, "day"), "day")) {
        return d.format("HH:mm  MM月DD日  明天");
      }
      // 本周
      if (d.isSame(moment(), "week")) {
        let week = d.day();
        switch (week) {
          case 1:
            return d.format("HH:mm  MM月DD日  星期一");
          case 2:
            return d.format("HH:mm  MM月DD日  星期二");
          case 3:
            return d.format("HH:mm  MM月DD日  星期三");
          case 4:
            return d.format("HH:mm  MM月DD日  星期四");
          case 5:
            return d.format("HH:mm  MM月DD日  星期五");
          case 6:
            return d.format("HH:mm  MM月DD日  星期六");
          case 0:
            return d.format("HH:mm  MM月DD日  星期日");
        }
      }
      // 本月和本年
      if (d.isSame(moment(), "month") || d.year() === moment().year()) {
        return d.format("HH:mm MM月DD日");
      }
      return d.format("HH:mm YYYY年MM月DD日");
    };

    const handle16 = d => {
      // 本月和本年
      if (d.year() === moment().year()) {
        return d.format("MM月DD日 HH:mm");
      }
      return d.format("YYYY年MM月DD日 HH:mm");
    };
    if (typeof type === "string") {
      return d.format(type);
    }
    switch (type) {
      case 0:
        return d.format("YYYY-MM-DD");
      case 1:
        return d.format("YYYY-MM-DD HH:mm:ss");
      case 2:
        return d.format("YYYY年MM月DD日");
      case 3:
        return d.format("YYYY年MM月DD日 HH:mm:ss");
      case 4:
        return d.format("MM月DD日 HH:mm");
      case 5:
        return d.format("MM月DD日");
      case 6:
        return handle6(d);
      case 7:
        return handle7(d);
      case 8:
        return d.format("YYYY年MM月DD日 HH:mm");
      case 9:
        return d.format("YYYY-MM-DD HH:mm");
      case 10:
        return handle10(d);
      case 11:
        return d.format("MM-DD");
      case 12:
        return d.format("MM-DD HH:mm");
      case 13:
        return handle13(d);
      case 14:
        return handle14(d, 1);
      case 15:
        return handle14(d, 2);
      case 16:
        return handle16(d);
    }
    return target;
  }
};
